<template>
	<div class="container">

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'profile-index'}">Profile</router-link>
				</li>
			</ol>
		</nav>

		<h5>Profile</h5>

		<agent-profile-form v-model="model" v-if="model" @submit="save"></agent-profile-form>

	</div>
</template>

<script>
	import AgentProfileForm from "../../components/agents/agent-profile-form";
	import api from '@/api'
	import messageBox from '@/components/modals/alert'

	export default {
		name: "index",
		data() {
			return {
				model: null
			}
		},
		async mounted() {
			this.model = await api.Me.getUser()
		},
		methods: {
			async save() {
				this.model = await api.Me.update(this.model)

				this.$store.commit("User/userData", this.model)

				messageBox({
					message: `Profile updated!`
				})
			}
		},
		components: {AgentProfileForm}
	}
</script>

<style scoped>

</style>
